import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import HoverLink from './HoverLink'
import { fade } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
    dropdown: {
        '&:hover, &:focus': {
            '& $subMenu': {
                visibility: 'visible',
                opacity: 1,
            },
        },
    },
    dropdownContent: {
        boxShadow: theme.shadows[2],
        padding: theme.spacing(1),
        display: 'none',
        position: 'absolute',
        zIndex: 1,
        top: '100%',
        backgroundColor: fade(theme.palette.primary.main, 0.7),
    },
    subMenu: {
        position: 'absolute',
        width: '100%',
        left: 0,
        backgroundColor: '#c60428',
        top: '100%',
        visibility: 'hidden',
        opacity: 0,
        transition: theme.transitions.create(['visiblity', 'opacity'], {
            // easing: 'ease, ease',
            // duration: '0s, 0.2s',
        }),
    },
    subMenuContainer: {
        display: 'flex',
        gap: theme.spacing(4),
    },
}))

export type Link = {
    name: string
    to: string
}
interface DropdownLinkProps {
    name: string
    links: Link[]
}

const DropdownLink = ({ name, links }: DropdownLinkProps) => {
    const classes = useStyles()
    return (
        <Box display="flex" className={classes.dropdown}>
            <HoverLink name={name} variant="h5" />
            <div className={classes.subMenu}>
                <Container maxWidth="md" className={classes.subMenuContainer}>
                    {links.map(l => (
                        <HoverLink key={l.name} name={l.name} to={l.to} variant="h6" />
                    ))}
                </Container>
            </div>
        </Box>
    )
}

export default DropdownLink
