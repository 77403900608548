import React, { useState } from 'react'
import { Typography, Collapse, easing } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
interface StyleProps {
    expanded: boolean
}

// this component is styled in MobileDrawer
const useStyles = makeStyles(theme => ({
    ulSubMenu: {
        background: '#fff',
        '& > li': {
            color: theme.palette.primary.main,
            borderBottom: `1px ${theme.palette.primary.main} solid`,
            '& > a': {
                display: 'block',
            },
        },
    },
    liDrop: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    expandIcon: {
        transform: ({ expanded }: StyleProps) => expanded && 'rotate(-180deg)',
        transition: theme.transitions.create('transform', {
            duration: 200,
            easing: easing.easeInOut,
        }),
        marginRight: theme.spacing(1),
    },
}))

interface Props {
    link: Link
    childLinks?: Link[]
}
interface Link {
    name: string
    to?: string
}

function MobileDrawerLi({ link, childLinks }: Props) {
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles({ expanded })

    if (!childLinks?.length)
        return (
            <li>
                <GatsbyLink to={link.to}>
                    <Typography variant="h6">{link.name}</Typography>
                </GatsbyLink>
            </li>
        )
    return (
        <>
            <li className={clsx(classes.liDrop)} onClick={() => setExpanded(expanded => !expanded)}>
                <div style={{ width: 24 }} />
                <Typography variant="h6" component="span">
                    {link.name}
                </Typography>
                <ExpandMoreRoundedIcon className={classes.expandIcon} />
            </li>
            <Collapse in={expanded}>
                <ul className={classes.ulSubMenu}>
                    {childLinks.map(l => (
                        <li key={l.to}>
                            <GatsbyLink to={l.to}>
                                <Typography variant="h6">{l.name}</Typography>
                            </GatsbyLink>
                        </li>
                    ))}
                </ul>
            </Collapse>
        </>
    )
}

export default MobileDrawerLi
