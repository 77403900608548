import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
const headingFontFam = {
    fontFamily: 'Quantico, Helvetica, Arial, sans-serif',
}

const theme = responsiveFontSizes(
    createMuiTheme({
        palette: {
            primary: {
                light: '#d30029',
                main: '#d30029',
            },
            secondary: {
                main: '#00174d',
                dark: '#06122b',
            },
        },
        typography: {
            h1: headingFontFam,
            h2: headingFontFam,
            h3: headingFontFam,
            h4: headingFontFam,
            h5: headingFontFam,
            h6: headingFontFam,
        },
    })
)

theme.overrides = {
    MuiButton: {
        root: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 0,

            fontSize: '1rem',
            '&.MuiButton-root:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        text: {
            padding: theme.spacing(1.5),
        },
    },
}

export default theme
