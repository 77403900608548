import React from 'react'
import { ClickAwayListener, makeStyles } from '@material-ui/core'
import MobileDrawerLi from './MobileDrawerLi'
import { graphql, useStaticQuery } from 'gatsby'
import { TeamLinksQuery } from '../../../graphql-types'
import { Link } from './DropdownLink'

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        marginTop: theme.spacing(0.5),
        position: 'absolute',
        background: theme.palette.primary.main,
        width: '100%',
        top: '100%',
        left: 0,
        padding: theme.spacing(2, 0, 0),
        '& ul': {
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            margin: 0,
            textAlign: 'center',
            '& > li': {
                textTransform: 'uppercase',
                padding: theme.spacing(1, 0),
            },
        },
        '& > ul': {
            '& > li': {
                color: '#fff',
                '&:not(:last-child)': {
                    borderBottom: '1px #fff solid',
                },
            },
        },
    },
}))

function MobileDrawer({
    open,
    setDrawerOpen,
}: {
    open: boolean
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const data = useStaticQuery<TeamLinksQuery>(query)
    const teamLinks: Link[] = data.allSanityTeam.nodes.map(t => {
        return { name: t.name, to: '/teams/' + t.slug.current }
    })

    const classes = useStyles()

    const handleClickAway = (event: React.MouseEvent<Document, MouseEvent>) => {
        event.stopPropagation()
        event.preventDefault()
        setDrawerOpen(false)
    }

    if (!open) return <></>
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.drawer}>
                <ul>
                    <MobileDrawerLi link={{ to: '/news', name: 'News' }} />
                    <MobileDrawerLi link={{ to: 'Teams', name: 'Teams' }} childLinks={teamLinks} />
                    <MobileDrawerLi link={{ to: '/schedule', name: 'Spielplan' }} />
                    <MobileDrawerLi link={{ to: '/contact', name: 'Kontakt' }} />
                </ul>
            </div>
        </ClickAwayListener>
    )
}

export default MobileDrawer

const query = graphql`
    query MobileTeamLinksQuery {
        allSanityTeam {
            nodes {
                id
                name
                slug {
                    current
                }
            }
        }
    }
`
