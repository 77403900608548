import { AppBar, Container, Box, makeStyles, IconButton, Hidden, easing } from '@material-ui/core'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { TeamLinksQuery } from '../../../graphql-types'
import DropdownLink, { Link } from './DropdownLink'
import HoverLink from './HoverLink'
import Logo_VTH from './VTHLogo.svg'
import MenuIcon from '@material-ui/icons/Menu'
import MobileDrawer from './MobileDrawer'
import { globalHistory } from '@reach/router'
const appBarHeight = 60

const useStyles = makeStyles(theme => ({
    appBar: {
        paddingLeft: theme.spacing(2),
    },
    toolbar: {
        alignItems: 'end',
    },
    linkContainer: {
        // height: appBarHeight,
    },
    logo: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        left: 0,

        '& img': {
            cursor: 'pointer',
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.easeOut,
            }),
            zIndex: 1,
            height: '100px',
            marginTop: '8px',
            [theme.breakpoints.down('sm')]: {
                height: '60px',
                marginTop: '6px',
            },
            '&:hover': {
                transform: 'scale(1.1)',
            },
        },
    },
    offset: {
        minHeight: appBarHeight,
    },
    boxLeft: {
        display: 'flex',
        flexBasis: '50%',
        marginRight: '70px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    boxRight: {
        display: 'flex',
        flexBasis: '50%',
        justifyContent: 'flex-end',
        marginLeft: '70px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
}))

const Header = () => {
    const data = useStaticQuery<TeamLinksQuery>(teamLinkQuery)
    const teamLinks: Link[] = data.allSanityTeam.nodes.map(t => {
        return { name: t.name, to: '/teams/' + t.slug.current }
    })
    const classes = useStyles()

    const [drawerOpen, setDrawerOpen] = useState(false)

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') setDrawerOpen(false)
        })
    }, [setDrawerOpen])

    return (
        <>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <GatsbyLink to="/">
                    <div className={classes.logo}>
                        <img src={Logo_VTH} alt="VTH Logo" />
                    </div>
                </GatsbyLink>
                <div className={classes.toolbar}>
                    <Hidden mdUp>
                        <IconButton
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setDrawerOpen(open => !open)}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        <Container maxWidth="md" className={classes.linkContainer}>
                            <Box display="flex">
                                <Box className={classes.boxLeft}>
                                    <HoverLink
                                        name="News"
                                        to="/news"
                                        variant="h5"
                                        style={{ marginRight: '20%' }}
                                    />
                                    <DropdownLink name="Teams" links={teamLinks} />
                                </Box>
                                <Box className={classes.boxRight}>
                                    <HoverLink name="Spielplan" to="/schedule" variant="h5" />
                                    <HoverLink
                                        name="Kontakt"
                                        to="/contact"
                                        variant="h5"
                                        style={{ marginLeft: '20%' }}
                                    />
                                </Box>
                            </Box>
                        </Container>
                    </Hidden>
                </div>
                <MobileDrawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </AppBar>

            <div className={classes.offset} />
        </>
    )
}

export default Header

export const teamLinkQuery = graphql`
    query TeamLinks {
        allSanityTeam {
            nodes {
                id
                name
                slug {
                    current
                }
            }
        }
    }
`
