import React, { createContext, useContext, useState } from "react"
import SEO from "../Shared/SEO"

const Context = createContext(null)

interface SEOState {
  title?: string
  description?: string
  image?: string
  article?: boolean
}

export const useSeoContext = () =>
  useContext<{ setSeo: React.Dispatch<React.SetStateAction<SEOState>> }>(
    Context
  )

const SEOProvider: React.FC<{ children }> = ({ children }) => {
  const [seo, setSeo] = useState<SEOState>({})
  return (
    <Context.Provider value={{ setSeo }}>
      <SEO {...seo} />
      {children}
    </Context.Provider>
  )
}
export default SEOProvider
