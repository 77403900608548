import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'gatsby'
import { TypographyProps } from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useLocation } from '@reach/router'
interface StyleProps {
    dark: boolean
}
const useStyles = makeStyles(theme => ({
    gatsbyLink: {
        color: ({ dark }: StyleProps) =>
            dark ? theme.palette.common.black : theme.palette.common.white,
        textDecoration: 'unset',
    },
    link: {
        cursor: 'pointer',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            bottom: 0,
            left: 0,
            backgroundColor: ({ dark }: StyleProps) =>
                dark ? theme.palette.common.black : theme.palette.common.white,
            visibility: 'hidden',
            transform: 'scaleX(0)',
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.easeOut,
            }),
        },
    },
    hover: {
        '&:hover': {
            '&:before': {
                visibility: 'visible',
                transform: 'scaleX(1)',
            },
        },
    },
    selected: {
        '&:before': {
            visibility: 'visible',
            transform: 'scaleX(1)',
        },
    },
    typo: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        textTransform: 'uppercase',
    },
}))

interface Props {
    name: string
    variant: TypographyProps['variant']
    style?: React.CSSProperties
    to?: string
    dark?: boolean
}

const isSelected = (to, name, pathname) => {
    return (to && to == pathname) || name.toLowerCase() == pathname.split('/')[1].toLowerCase()
}

const HoverLink: React.FC<Props> = ({ name, style, variant, children, to, dark }) => {
    const classes = useStyles({ dark: dark })
    const { pathname } = useLocation()

    const typo = (
        <Box
            display="flex"
            flexDirection="row"
            className={clsx(
                classes.link,
                isSelected(to, name, pathname) ? classes.selected : classes.hover
            )}
            alignItems="center">
            {children && <Box pr={0.5}>{children}</Box>}

            <Typography noWrap variant={variant} component="div" className={classes.typo}>
                {name}
            </Typography>
        </Box>
    )
    return (
        <Box display="flex" style={style}>
            {to ? (
                <Link to={to} className={classes.gatsbyLink}>
                    {typo}
                </Link>
            ) : (
                typo
            )}
        </Box>
    )
}

export default HoverLink
