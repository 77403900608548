import React from "react"
import { CssBaseline, makeStyles } from "@material-ui/core"
import Footer from "./Footer"
import Header from "./Header"
import "./global.css"

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    overflowX: "hidden",
  },
  content: {
    flex: 1,
    backgroundColor: theme.palette.grey[200],
  },
}))

const Layout: React.FC<{ children }> = ({ children }) => {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <div className={classes.main}>
        <Header />
        <div className={classes.content}>{children}</div>
        <Footer />
      </div>
    </>
  )
}
export default Layout
