import { Box, makeStyles } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import React from 'react'
import { Link } from 'gatsby'
interface StyleProps {
    dark: boolean
}

const useStyles = makeStyles(theme => ({
    footer: {
        zIndex: 1,
        backgroundColor: theme.palette.primary.main,
        // boxShadow: "inset 0 10px 10px -10px rgb(0 0 0.2)",
    },
    icon: {
        cursor: 'pointer',
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeOut,
        }),
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '&:hover': {
            // transform: "scale(1.2)",
            color: theme.palette.secondary.main,
        },
    },
    gatsbyLink: {
        color: theme.palette.common.white,
        textDecoration: 'unset',
        lineHeight: '3.4',
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    coder: {
        color: theme.palette.common.white,
        fontSize: theme.typography.caption.fontSize,
        fontFamily: theme.typography.h1.fontFamily,
    },
}))

const Footer = () => {
    const classes = useStyles()

    return (
        <footer className={classes.footer}>
            <Box display="flex">
                <Box display="flex" flex={1} alignItems="center" ml={2}>
                    <a href="mailto:janek9527@gmail.com" className={classes.coder} rel="noreferrer">
                        {'{ Janek Gass }'}
                    </a>
                </Box>
                <Box display="flex" flex={1} justifyContent="center">
                    <a
                        title="VTHaemmerz auf Facebook"
                        href="https://www.facebook.com/vthaemmerz/"
                        target="_blank"
                        rel="noreferrer">
                        <FacebookIcon className={classes.icon} />
                    </a>
                    <a
                        title="VTHaemmerz auf Instagram"
                        href="https://www.instagram.com/vthaemmerz/"
                        target="_blank"
                        rel="noreferrer">
                        <InstagramIcon className={classes.icon} />
                    </a>
                </Box>
                <Box display="flex" flex={1} justifyContent="flex-end" mr={2}>
                    <Link to="/impressum" className={classes.gatsbyLink}>
                        Impressum
                    </Link>
                </Box>
            </Box>
        </footer>
    )
}

export default Footer
